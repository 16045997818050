.rouned-shap {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    filter: drop-shadow(1px 10px 10px #949494a2);
  }
  
  .icon-shape {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .title-Shape {
    font-weight: bold;
    font-size: 16px;
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .rouned-shap {
      width: 120px; /* Adjust for smaller screens */
      height: 120px;
    }
  
    .icon-shape {
      font-size: 18px; /* Adjust icon size */
      margin-bottom: 8px; /* Adjust spacing */
    }
  
    .title-Shape {
      font-size: 14px; /* Adjust title font size */
    }
  }