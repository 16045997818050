@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');


body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
};


