.service-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 100px;
    
}


.service-menu {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.rounded-gray-shape {

    width: 450px; 
    height: 450px;
    border-radius: 50%;
    background-color:#0551AC;
    opacity: 4%; 
    z-index: 0;
    margin-top: -200px;
    float: right;
  } 


  @media (max-width: 768px) {
    .service-content {

        padding: 0px 30px;
        
    }
  }
  @media (max-width: 768px) {
    .rounded-gray-shape {
      width: 300px; /* Adjust dimensions for smaller screens */
      height: 300px;
      margin-top: -150px; /* Adjust margin for spacing */
      float: none; /* Remove float for centering */
      margin-left: auto; /* Center horizontally */
      margin-right: auto;
    }
  }