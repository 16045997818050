
.App-container{
  width: 100% !important;
  height: 100%;

}


a {
  text-decoration: none !important;
  color: inherit;
}

