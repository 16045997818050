.services-container {
  position: relative;
  display: flex;
  flex-direction: row;
  /* Change to column layout for responsiveness */
  width: 80%;
  margin-top: 50px;
}

.services-content {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
}

.content-titel {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 20px;
  /* Adjust spacing */
}

.bold-titel {
  font-size: 36px;
  /* Adjust font size */
  font-weight: bold;
  margin-bottom: 10px;
}

.titel-discription {
  font-size: 14px;
  width: 100%;
  /* Adjust width */
  max-width: 270px;
  /* Limit the width on larger screens */
}

.services-content-shaps {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -50px;
}

.first-line {
  display: flex;
  width: 100%;
  /* Adjust width */
  flex-wrap: wrap;
  /* Allow items to wrap on smaller screens */
  justify-content: center;
  /* Center items */
  align-items: center;
  gap: 450px;
  /* Horizontal spacing between items */
}

.second-line {
  display: flex;
  width: 100%;
  /* Adjust width */
  flex-wrap: wrap;
  /* Allow items to wrap on smaller screens */
  justify-content: center;
  /* Center items */
  gap: 20px;
  /* Horizontal spacing between items */
  margin-top: 20px;
  /* Adjust spacing */
}

@media (max-width: 1455px) {
  .first-line {
    gap: 200px;
    /* Adjust spacing between items */
  }

  .services-content-shaps {
    margin-top: 0px;

  }
}

@media (max-width: 768px) {
  .services-container {
    flex-direction: column;
    align-items: center;
  }

  .services-content-shaps {
    margin-top: 0px;

  }

  .first-line {
    gap: 20px
  }

  .second-line {
    gap: 0px;
    /* Adjust spacing between items */
  }

  .box-open-container {
    margin-top: 20px;
  }
}