.spinner-container {
position: fixed;
    width: 200px;
    height: 200px;
    animation: spin 3s linear infinite;
    z-index: 100;
    /* Adjust the animation duration as needed */

}

.spinner {
    position: relative;
    width: 100%;
    height: 100%;
    background: transparent;

}

.circle {
    position: relative;
    width: 100%;
    height: 100%;
    animation: rotate 2s linear infinite;
}

.circle:before {
    content: "";
    position: absolute;
    z-index: -1;
    inset: 0;
    background:
        /*this is your grey background*/
        linear-gradient(to right, #0551AC, rgba(128, 128, 128, 0.164)) border-box;

    border-bottom: 20px solid transparent;
    border-right: 20px solid transparent;
    border-left: 20px solid transparent;
    border-top: 20px solid rgba(255, 255, 255, 0);
    border-radius: 50%;
    -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;

}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}