.layout-container {
    width: 100% ;
    display: flex;
    flex-direction: column;
    min-height: 100vh; 

}

.main-layout {
    width: 100%;
    flex-grow: 1; /* Allow main content to grow and push footer down */
    padding-bottom: 100px; 
    position: relative;/* Minimum viewport height */

}

.blue-shape {
    width: 100%;
    height: 576px;
    background-color: #024180;
    position: absolute;
    z-index: -3;
}

.rounded-shape {
    content: '';
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    height: 450px;
    border-radius: 50%;
    background-color: #0551AC;
    opacity: 4%;
    z-index: -1;

}

.container-loader {
    z-index: 99;
    width: 100%;
    height: 100%;
    position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}


.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(252, 252, 252, 0.404);
    /* Adjust the opacity (last value) as needed */
    z-index: 50;
    /* Place the overlay above other content */ 
}

.ad-left {
    position: absolute;
    left: 20px; 
    transform: translate(0, -50%);
    background-color: #fff; 
    padding: 10px; 
    z-index: 0;
    display: flex;
    flex-direction: column;
    background: none;
    top: 48% !important; 
  }
  
  .ad-right {
    position: absolute;
    right: 20px; 
    transform: translate(0, -50%);
    background-color: #fff; 
    padding: 10px; 
    z-index: 0;
    display: flex;
    flex-direction: column;
    background: none;
    top: 48% !important; 
  }
  @media (max-width: 768px) {
    /* Adjust styles for smaller screens */
    .ad-left,
    .ad-right {
      display: none; /* Hide the ads on smaller screens */
    }}

@media (max-width: 768px) {
    .blue-shape {
        height: 1000px; /* Change to column layout for smaller screens */
    }
}