.social-media-container {
 right: 0;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: flex-end;
    position: fixed;
    margin-top: 200px;
    flex-direction: column;
    height: 100px;
    justify-content: space-around;
    z-index:50;
    pointer-events:visible;

}

.social-media-container a {
    width: 60px;
    height: 60px;
}