.header-container {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 80px;
    font-weight: bold;
    color: #3e4958;
    font-size: 21px;
    background: white;
    border-bottom: 2px solid lightgray;
    box-shadow: 1px 1px 5px;

  }
  
  .navigation {
    width:450px;
    display: flex;
    justify-content: space-evenly;
  }
  
  .language {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 320px;
  }
  
  .language-icon {
    color: #999999;
    margin-right: 5px;
  }
  
  /* Media query for responsiveness */
  @media (max-width: 768px) {
    .header-container {
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 150px; 
      z-index: 3;
      /* Adjust padding for smaller screens */
    }
  
    .navigation {
      width: 100%; /* Full width for smaller screens */
      justify-content: space-around; /* Center navigation links */
      margin-top: 10px;
       /* Adjust margin for spacing */
    }
  
    .language {
      margin-top: 10px; /* Adjust margin for spacing */
    }
  }