.titel-container {
  margin: 50px 0px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
  text-align: center;
  padding: 0px 200px;
}

.titel-container .titel {
  max-width: 800px;
  font-size: 40px;
  /* Default font size */
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  /* Adjust spacing */
}

@media (max-width: 1040px) {
  .titel-container {

    padding: 0px 20px
      /* Center text */
      /* Change to column layout for smaller screens */
  }
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .titel-container {
    flex-direction: column;
    margin-bottom: 20px;
    padding: 0px
      /* Center text */
      /* Change to column layout for smaller screens */
  }

  .titel-container .titel {
    font-size: 30px;
    margin: 20px;
    /* Adjust font size for smaller screens */
  }
}