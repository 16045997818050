.service-description-container {
    margin-top: -100px;
    width: 68%;
    margin: 0 auto; 
    display: flex;
    flex-direction: column;
    align-items: center;/* Center the container */
  }
  
  .service-description-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Vertical gap between elements */
  }
  
  .description-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Vertical gap between elements */
  }
  
  .description-image {
    width: 100%;
  }
  
  .description-image img {
    width: 100%;
    height: auto;
    z-index: 10;
  }
  
  .description-content {
    text-align: center; /* Center the content */
    width: 100%;
    
  }
  
  .integrations {
    margin-bottom: 15px; /* Adjust spacing */
    font-size: 14px; /* Adjust font size */
    font-weight: bold;
    color: #0551AC;
    text-transform: uppercase;
  }
  
  .service-description-title {
    margin-bottom: 15px; /* Adjust spacing */
    font-size: 20px; /* Adjust font size */
    font-weight: bold;
  }
  
  .service-description-paragraph {
    width: 100%;
  }
  .down-pub{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
  }
  
  @media (min-width: 768px) {
    .service-description-content {
      margin-top: -100px;

      flex-direction: row;
      align-items: flex-start;
      gap: 40px;
    }
  
    .description-container {
        display: flex;
        flex-direction: row-reverse;
      width: 100%;
      align-items: flex-start;
    }
  
    .description-image,
    .description-content {
      width: 50%;
      z-index: 1;

    }
  
    .description-image img {
      width: 100%;
    }
  
    .description-content {
      text-align: left;
    }
  }