@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&family=Source+Sans+3:wght@500&display=swap');

.service-input-container {
    width: 65%;
    height: 497px;
    background: #F1F3F9;
    border-radius: 20px;
    box-shadow: 0px 3px 7px rgb(219, 213, 213);
    padding: 20px;
    margin-bottom: 50px;
    z-index: 10;
    
}

.input-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.mid-input-header {
    width: 47%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.input-content{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.first-input{
    width: 48%;
}
.upload-content{
    background: white;
    height: 32px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(128, 128, 128, 0.137);
}

.word-lenght-content{
    height: 30px;
    border-bottom-left-radius:20px ;
    border-bottom-right-radius: 20px;
    background-color: white;
    display: flex;
    align-items: center;
    padding: 10px;
justify-content: space-between;

}

.word-lenght{
    font-size: 12px;
}

.input-title{

    color: #024180;
    font-size: 20px;
    font-weight:bold;
    font-family: 'Source Sans 3', sans-serif;
}

.switch-icon {
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 50%;
}

.switch-button {
    cursor: pointer;
}

.button :hover {
    background-color: rgba(128, 128, 128, 0.055);
}

.download-content{
    background: white;
    height: 82px;
    border-bottom-left-radius:20px ;
    border-bottom-right-radius: 20px;
    padding:0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .service-input-container {
      width: 100%; /* Full width for smaller screens */
      padding: 10px; /* Adjust padding */
      height: auto; /* Allow height to adjust based on content */
    }
  
    .input-header {
      flex-direction: column; /* Stack elements in a column */
      align-items: center;
      gap: 10px; /* Adjust spacing */
      margin-bottom: 10px; /* Adjust spacing */
    }
  
    .mid-input-header {
      width: 100%; /* Full width for smaller screens */
      flex-direction: column; /* Stack elements in a column */
      gap: 5px; /* Adjust spacing */
      align-items: center;
      text-align: center; /* Center text */
    }
  
    .input-content {
      flex-direction: column; /* Stack elements in a column */
      gap: 10px; /* Adjust spacing */
    }
  
    .first-input {
      width: 100%; /* Full width for smaller screens */
    }
  
    .upload-content,
    .download-content {
      flex-direction: column; /* Stack elements in a column */
      gap: 5px; /* Adjust spacing */
      height: auto; /* Allow height to adjust based on content */
      padding: 10px 0; /* Adjust padding */
      text-align: center; /* Center text */
    }
  
    .upload-content {
      border-bottom-left-radius: 0; /* Remove border radius */
      border-bottom-right-radius: 0; /* Remove border radius */
    }
  
    .word-lenght-content {
        height: auto;
      flex-direction: column; /* Stack elements in a column */
      gap: 5px; /* Adjust spacing */
      border-radius: 0; /* Remove border radius */
      padding: 5px 0; /* Adjust padding */
      text-align: center; /* Center text */
    }
    
    /* Add additional styles as needed */
  }