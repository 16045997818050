.footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background: #024180;
    text-align: center;
    color: white;
    font-size: 14px;
    margin-top: 50px;
    padding: 10px 0; /* Add padding for better spacing */
  }
  
  .footer-social {
    display: flex;
    justify-content: space-around;
    width: 100%; /* Use full width for smaller screens */
    max-width: 150px; /* Limit width on larger screens */
    margin-bottom: 10px; /* Add spacing between sections */
  }
  
  .footer-service {
    display: flex;
    justify-content: center;
    width: 100%; /* Use full width for smaller screens */
    margin-bottom: 10px; /* Add spacing between sections */
  }
  
  .footer-service a {
    border-right: 1px solid white;
    padding: 0 10px; /* Add padding for better spacing */
  }
  
  .footer-service a:last-child {
    border-right: none;
  }
  
  .footer-policy-container {
    background: #0550ac85;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    width: 100%; /* Add padding for better spacing */
  }
  
  .footer-policy {
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 350px; /* Limit width on larger screens */
  }
  
  .footer-policy a {
    border-right: 1px solid rgba(255, 255, 255, 0.575);
    padding: 0 5px; /* Add padding for better spacing */
  }
  
  .footer-policy a:last-child {
    border-right: none;
  }
  
  .footer-policy-link {
    display: flex;
    justify-content: space-around;
    width: 100%; /* Use full width for smaller screens */
    max-width: 200px; /* Limit width on larger screens */
  }