.pub-container {
  position: relative; 
  max-width: 300px;
 height: 340px;
  margin-top: 150px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(to bottom, #00afdf,#f1f1f1); /* Gray linear gradient */
  padding: 10px;
}

.pub-Link{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;

}


.description-pub {
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 14px;
}


.pub-promo {
  color: white;
  font-size: 14px;
  font-weight: bold;
  width: 100px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: linear-gradient(60deg, #590ab9, #e1bef1);
  border-radius: 50%;
  position: absolute; /* Ajoutez cette ligne */
  left: 25px;
  top:80px; /* Positionnement dans le coin supérieur gauche */
  transform: rotate(320deg);
}

.shop-button{
  margin-top: 10px;
  display: flex;
  min-width: 115px;
  min-height: 30px;
  padding: 7px;
  background-color: #730ab9;
  border-radius: 50px;
  align-items: center;
  justify-content: space-between;
}

.shop-icon{
border-radius: 50%;
background-color: transparent;
border: 2px solid white; 
width: 28px;
height: 28px;
padding: 1px;
display: flex;
align-items: center;
justify-content: center;

}
.shop-text{
  font-size: 14px;
  font-weight: bold;
  color: white;

}

@media (max-width: 768px) {
  .pub-container {
    max-width: 100%;
    margin-top: 50px;
  }

  .pub-container img {
    width: 100%;
    max-width: 200px;
    height: auto;
  }

  .pub-promo {
    width: 60px;
    height: 30px;
    font-size: 12px;
    left: 10px;
  }}