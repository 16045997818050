.language-selector-container {
    display: flex;
    align-items: center;
    width: 100px;
}

.flags-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}