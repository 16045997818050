
.services-menu-container {
    width: 50%; /* Full width for smaller screens */
    height: 32px;
    border: 1px solid white;
    background-color: #024180 ;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    z-index: 0;
  }
  
  .services-menu-item,
  .services-menu-item-selected {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    width: 100%; /* Full width for smaller screens */
    height: 32px; 
    white-space: nowrap;/* Match the container's height */
  }
  
  .services-menu-item:hover {
    background-color: #1f74fe3d;
  }
  
  .services-menu-item-selected {
    background: #1f76fe;
  }

  @media (max-width: 845px) {
    .services-menu-container {
     width: 45% !important;
     flex-wrap: wrap;
     height: auto;
    }
}